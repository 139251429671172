/***
 * Audio
 */
import Plyr from 'plyr';

export default (config) => ({
  init() {
    const player = new Plyr(this.$el, {
      controls: ['play', 'progress', 'current-time'],
      iconUrl: '/themes/monarchie_kids/src/img/icons/plyr-audio.svg',
      ...config
    });
    player.on('ready', (event) => {
      event.detail.plyr.elements.display.seekTooltip.setAttribute('role', 'tooltip')
    });
  },
})