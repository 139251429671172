/**
 * How to use ?
 * Example :
  const medias = document.querySelectorAll('img, video');
  new MediasLoaded(medias).then(() => {
    console.log('All medias loaded !');
  });
 */

export default class MediasLoaded {

  constructor(medias) {
    const promises = [];

    medias.forEach((element) => {

      // Image
      if (element.tagName === 'IMG') {
        promises.push(new Promise((resolve) => {
          const img = new Image();
          img.onload = resolve;
          img.onerror = resolve;
          img.src = element.src;
        }));

      // Video
      } else if (element.tagName === 'VIDEO') {
        promises.push(new Promise((resolve) => {
          if (element.readyState >= 3 || element.error) {
            resolve();
          } else {
            element.addEventListener('loadeddata', resolve);
            element.addEventListener('error', resolve);
            setTimeout(resolve, 4000);
          }
        }));
      }
    });

    return Promise.all(promises);
  }

}