/***
 * A11y
 */
export default {
  options: {
    animations: true,
    leading: false,
    typography: false,
  },

  // Init
  init() {
    if(localStorage.getItem('a11y') == null) {
      this.setLocalStorage();
    } else {
      this.options = JSON.parse(localStorage.getItem('a11y'));
    }

    // Animations body class
    if(!this.options.animations) {
      document.documentElement.classList.add('a11y-animations')
    } else {
      document.documentElement.classList.remove('a11y-animations')
    }

    // Leading body class
    if(this.options.leading) {
      document.documentElement.classList.add('a11y-leading')
    } else {
      document.documentElement.classList.remove('a11y-leading')
    }

    // Typography body class
    if(this.options.typography) {
      document.documentElement.classList.add('a11y-typography')
    } else {
      document.documentElement.classList.remove('a11y-typography')
    }

  },

  // Animations
  toggleAnimations() {

    // Set
    this.options.animations = !this.options.animations;
    this.setLocalStorage();

    // Body class
    if(!this.options.animations) {
      document.documentElement.classList.add('a11y-animations')
    } else {
      document.documentElement.classList.remove('a11y-animations')
    }
    
    // Dispatch event
    let event = new CustomEvent("a11yAnimations", { detail: this.options.animations });
    document.dispatchEvent(event);
    
  },

  // Leading
  toggleLeading() {

    // Set
    this.options.leading = !this.options.leading;
    this.setLocalStorage();

    // Body class
    if(this.options.leading) {
      document.documentElement.classList.add('a11y-leading')
    } else {
      document.documentElement.classList.remove('a11y-leading')
    }

    // Dispatch event
    let event = new CustomEvent("a11yLeading", { detail: this.options.leading });
    document.dispatchEvent(event);

  },

  // Typography
  toggleTypography() {

    // Set
    this.options.typography = !this.options.typography;
    this.setLocalStorage();

    // Body class
    if(this.options.typography) {
      document.documentElement.classList.add('a11y-typography')
    } else {
      document.documentElement.classList.remove('a11y-typography')
    }

    // Dispatch event
    let event = new CustomEvent("a11yTypography", { detail: this.options.typography });
    document.dispatchEvent(event);

  },

  // Set local storage
  setLocalStorage() {
    localStorage.setItem('a11y', JSON.stringify(this.options));
  }

}