/***
 * Transition
 */
import lottie from 'lottie-web/build/player/lottie_light.min.js';
import loaderEffect from '../../img/loader.json';
import { gsap, Circ } from "gsap";
gsap.registerPlugin(Circ);

export default {
    size: {
      width: window.innerWidth,
      height: window.innerHeight
    },
    container: document.querySelector('.page-transition'),
    shape: document.querySelector('.page-transition-shape'),
    shapeContainer: document.querySelector('.page-transition-shape-container'),
    loader: document.querySelector('.page-transition-loader'),
    lottieLoader: null,
    tl: null,

    setup() {
      
      this.lottieLoader = lottie.loadAnimation({
        container: this.loader,
        renderer: 'svg',
        autoplay: false,
        loop: true,
        animationData: loaderEffect,
      });

      gsap.set(this.shapeContainer, { 
        transformOrigin: "center center", 
        scaleY: 1.5
      });
      gsap.set(this.shape, { 
        transformOrigin: "center top", 
        yPercent: 100,
        'will-change': 'transform',
      });
      gsap.set(this.loader, { 
        opacity: 0,
        scale: 0
      });
      gsap.set(this.container, { 
        'pointer-events': 'none',
      });

      this.tl = gsap.timeline({ 
        paused: true, 
        onStart: () => {
          gsap.set(this.container, { 
            'pointer-events': 'auto',
          });
        },
        onComplete: () => {
          gsap.set(this.container, { 
            'pointer-events': 'none',
          });
          gsap.set(this.shapeContainer, { 
            scaleY: 1.5
          });
          gsap.set(this.shape, { 
            yPercent: 100,
          });
          let transitionCompletedEvent = new CustomEvent("transitionCompleted", {
            detail: true
          });
          document.dispatchEvent(transitionCompletedEvent);
        },
        defaults: { duration: 1, ease: Circ.easeInOut }
      })
      .to(this.shape, { yPercent: 0 })
      .to(this.loader, { opacity: 1, scale: 1 }, '<')
      .add(() => { this.lottieLoader.play(); }, '<')
      .addPause('half', () => {
        let transitionHalfEvent = new CustomEvent("transitionHalf", {
          detail: true
        });
        document.dispatchEvent(transitionHalfEvent);
      })
      .to(this.loader, { opacity: 0, scale: 0 }, '<')
      .to(this.shapeContainer, { scaleY: 1 }, '<')
      .to(this.shape, { yPercent: 0, scaleY: 0 }, '<')
      .add(() => { this.lottieLoader.stop(); });

    },

    out() {
      this.tl.seek(0);
      this.tl.play();
    },

    in() {
      this.tl.play();
    }
  }