/***
 * App
 */
export default {
  isReady: false,
  isAdmin: false,
  referrer: null,
  scroll: 0,
  enter() {
    this.isReady = true;
    let transitionCompletedEvent = new CustomEvent("appReady", {
      detail: this.isReady
    });
    document.dispatchEvent(transitionCompletedEvent);
    Alpine.store('music').playBg();
    Alpine.store('music').playFx('progress');
  },
  reset(options) {
    this.isReady = false;
    Alpine.store('progress').clear();
    Alpine.store('user').clear();
    window.location.replace(options.path);
  }
}