import { gsap, Power4, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class ScrollParallax {

  /**
   * Init Parallax
   */
  constructor(el, options = {}) {
    
    // Config
    this.config = {};
    this.config.directions = typeof options.directions !== 'undefined' ? options.directions : ['x', 'y'];

    // Get the target element
    this.el = el;

    // String selector passed
    if (typeof this.el === 'string' || this.el instanceof String) {
      this.el = document.querySelector(this.el);
    }

    // If empty, exit
    if(this.el == null) {
      console.error(el + ' does not exist.');
      return false;
    }

    // Get layers
    this.layers = this.el.querySelectorAll('[data-parallax-layer]');
    if(this.layers.length == 0) {
      console.error('The element does not have any layers "[data-parallax-layer]".');
      return false;
    }

    // Register tweens array
    this.tweens = [];

    // Start
    if(Alpine.store('a11y').options.animations) {
      this.start();
    }

    // Watch a11y event
    document.addEventListener('a11yAnimations', (e) => {
      if(e.detail) {
        this.start();
      } else {
        this.stop();
      }
    })

  }

  /**
   * Start animations
   */
  start() {
    gsap.utils.toArray(this.layers).forEach((layer, i) => {
      let speed = layer.getAttribute('data-parallax-speed');
      let offset = 20 * speed;
      let direction = layer.getAttribute('data-parallax-direction').split(',');
      let rotate = layer.hasAttribute('data-parallax-rotate') ? layer.getAttribute('data-parallax-rotate') : 0;
      this.tweens.push(gsap.fromTo(layer, {
        'will-change': 'transform',
        xPercent: 0,
        yPercent: 0,
        rotate: 0,
        }, {
        xPercent: direction.includes('x') ? offset : 0,
        yPercent: direction.includes('y') ? offset : 0,
        rotate: rotate,
        ease: 'linear',
        scrollTrigger: {
          trigger: layer,
          scrub: true
        },
      }))
    });
    ScrollTrigger.refresh();
  }

  /**
   * Stop animations
   */
  stop() {
    this.tweens.forEach(tween => {
      tween.kill();
      tween = null;
    });
    this.tweens = [];
    ScrollTrigger.refresh();
  }

}