/***
 * Menu
 */
import { gsap, Bounce, Power4 } from "gsap";
gsap.registerPlugin(Bounce);
gsap.registerPlugin(Power4);

export default {
  isOpen: false,
  menu: document.querySelector('.menu'),
  btn: document.querySelector('.menu-btn'),
  content: document.querySelector('.menu-content'),

  // Init
  init() {
    this.updateLinkToCurrentPage();
  },

  // If we click on link to go on the current page, close the menu
  updateLinkToCurrentPage() {
    const links = this.menu.querySelectorAll('a');
    links.forEach(link => {
      if(window.location.href == link.href) {
        link.addEventListener('click', () => {
          this.close();
        });
      }
    });
  },

  // Open
  open() {
    this.isOpen = true;

    gsap.set(this.btn, { pointerEvents: 'none' });
    
    let tl = gsap.timeline({
      defaults: { duration: 1 },
      onComplete: () => {
        gsap.set(this.btn, { pointerEvents: 'auto' });
      },
    })
    .fromTo(this.content, {
      opacity: 0,
    }, {
      opacity: 1,
      duration: .5,
      ease: Power4.easeOut
    })
    .fromTo(this.content, {
      y: -50,
      scale: .8,
    }, {
      y: 0,
      scale: 1,
      duration: .5,
      ease: Bounce.easeOut
    }, '<');

  },

  // Close
  close() {

    gsap.set(this.btn, { pointerEvents: 'none' });
    
    let tl = gsap.timeline({
      defaults: { duration: .5 },
      onComplete: () => {
        gsap.set(this.btn, { pointerEvents: 'auto' });
        this.isOpen = false;
      },
    })
    .to(this.content, {
      opacity: 0,
      scale: .95,
      y: -10,
      ease: Power4.easeOut
    });

  }

}