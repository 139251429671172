import Highway from '@dogstudio/highway';
import ScrollReveal from "../../utilities/scroll-reveal";

export default class PageRenderer extends Highway.Renderer {
  onEnter() {
  }
  onLeave() {
  }
  onEnterCompleted() {

    // Text animations
    let scrollReveal = new ScrollReveal({ container: this.wrap });
    
  }
  onLeaveCompleted() {
  }
}
