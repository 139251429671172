import Highway from '@dogstudio/highway';
import ScrollParallax from "../../utilities/scroll-parallax";
import ScrollReveal from "../../utilities/scroll-reveal";

export default class PageRenderer extends Highway.Renderer {
  onEnter() {
  }
  onLeave() {

    Alpine.store('music').stopFg();

  }
  onEnterCompleted() {

    // Music
    let music = document.querySelector('[data-music-fg]').dataset.musicFg;
    if(Alpine.store('app').isReady) {
      Alpine.store('music').playFg(music);
    } else {
      document.addEventListener('appReady', (e) => {
        Alpine.store('music').playFg(music);
      });
    }

    // Text animations
    let scrollReveal = new ScrollReveal({ container: this.wrap });

    // Section separators
    let sectionSeparators = Array.from(this.wrap.querySelectorAll('.section-separator')).map((p) => new ScrollParallax(p));

    // Decorations
    let decorations = Array.from(this.wrap.querySelectorAll('.decoration')).map((p) => new ScrollParallax(p));
    
  }
  onLeaveCompleted() {
  }
}
