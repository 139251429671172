import Lenis from '@studio-freight/lenis';
import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

export default class ScrollSmooth {

  constructor() {

    // Basic scroll
    // window.smoothScroll = () => {}

    // window.smoothScroll.start = () => {
    //   document.body.classList.remove('overflow-hidden');
    // }

    // window.smoothScroll.stop = () => {
    //   document.body.classList.add('overflow-hidden');
    // }

    // window.smoothScroll.scrollTo = (y) => {
    //   window.scrollTo({
    //     top: y,
    //     left: 0,
    //     behavior: "smooth",
    //   });
    // }

    // window.smoothScroll.resize = () => {}

    // Lenis scroll
    window.smoothScroll = new Lenis()

    // Update scroll trigger
    window.smoothScroll.on("scroll", (e) => {
      ScrollTrigger.update;
    });

    // Request animation frame
    gsap.ticker.add((time) => {
      window.smoothScroll.raf(time * 1000)
    });

    // Lag smoothing
    gsap.ticker.lagSmoothing(0)

  }

}