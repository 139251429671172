/** @type {import('tailwindcss').Config} */

import colors from 'tailwindcss/colors';
import plugin from 'tailwindcss/plugin';
import forms from '@tailwindcss/forms';
import typography from '@tailwindcss/typography';

export default {
    content: [
      './templates/**/*.twig',
      './templates/**/*.html.twig',
      './src/**/*.js',
      './src/img/*.svg'
    ],
    theme: {
      container: {
        center: true,
        padding: '2rem',
      },
      fontFamily: {
        serif: ['Grenette', 'serif'],
        sans: ['Populaire', 'sans-serif'],
        dyslexia: ['OpenDyslexic', 'sans-serif'],
      },
      extend: {
  
        // Font size
        fontSize: {
          '10xl': '11rem',
          '11xl': '14rem'
        },
  
        // Colors
        colors: {
          current: 'currentColor',
          transparent: 'transparent',
          'peach': {
            DEFAULT: '#FFC692',
            50: '#FFF6EE',
            100: '#FFF1E4',
            200: '#FFE6CF',
            300: '#FFDBBB',
            400: '#FFD1A6',
            500: '#FFC692',
            600: '#FFA95A',
            700: '#FF8B22',
            800: '#E96F00',
            900: '#B15400',
          },
          'eden': {
            DEFAULT: '#0E5A5A',
            50: '#B6F4EF',
            100: '#97EFE9',
            200: '#59E5DE',
            300: '#21D6D0',
            400: '#189896',
            500: '#0E5A5A',
            600: '#0C4C4D',
            700: '#0A3E40',
            800: '#083032',
            900: '#062325'
          },
          'himalaya': {
            DEFAULT: '#5E6317',
            50: '#D0E17E',
            100: '#C9DC6A',
            200: '#BED340',
            300: '#A6B62A',
            400: '#838C21',
            500: '#5E6317',
            600: '#4C4E12',
            700: '#393A0D',
            800: '#252509',
            900: '#101004'
          },
        },
  
        // Radius
        borderRadius: {
          '4xl': '2rem'
        },
  
        // Drop Shadows
        boxShadow: {
          'sm': '0 3px 0 rgb(14 90 90 / 0.05)'
        },
        dropShadow: {
          'sm': '0 3px 0 rgb(14 90 90 / 0.05)'
        },
  
        // Animations
        animation: {
          wiggle: 'wiggle 1.5s ease-in-out infinite',
          jello: 'jello 0.75s ease-in-out both',
          bounce: 'bounce 0.75s ease-in-out infinite',
        },
        keyframes: {
          wiggle: {
            '0%, 100%': { transform: 'rotate(-6deg)' },
            '50%': { transform: 'rotate(6deg)' },
          },
          jello: {
            '0%': { transform: 'scale3d(1, 1, 1)' },
            '30%': { transform: 'scale3d(1.25, 1, 1)' },
            '40%': { transform: 'scale3d(1, 1.25, 1)' },
            '50%': { transform: 'scale3d(1.15, 0.85, 1)' },
            '65%': { transform: 'scale3d(0.95, 1.05, 1)' },
            '75%': { transform: 'scale3d(1.05, 0.95, 1)' },
            '100%': { transform: 'scale3d(1, 1, 1)' },
          },
          bounce: {
            '0%, 100%': { 
              transform: 'translateY(0)',
              animationTimingFunction: 'cubic-bezier(0, 0, 0.2, 1)'
            },
            '50%': {
              transform: 'translateY(-5%)',
              animationTimingFunction: 'cubic-bezier(0.8, 0, 1, 1)'
            },
          },
        },
        transitionTimingFunction: {
          'out-expo': 'cubic-bezier(0.19, 1, 0.22, 1)',
        },
  
        // Prose
        typography: (theme) => ({
          DEFAULT: {
            css: {
              '--tw-prose-headings': theme('colors.eden.500'),
              '--tw-prose-body': theme('colors.eden.500'),
              '--tw-prose-links': theme('colors.peach.900'),
              '--tw-prose-bullets': theme('colors.peach.900'),
              '--tw-prose-counters': theme('colors.peach.900'),
              '--tw-prose-quotes': theme('colors.eden.500'),
              '--tw-prose-quote-borders': theme('colors.peach.500'),
              '--tw-prose-td-borders': theme('colors.peach.300'),
              '--tw-prose-lead': theme('colors.eden.500'),
              '--tw-prose-bold': theme('colors.eden.500'),
              h1: {
                fontFamily: theme('fontFamily.sans'),
                fontWeight: 'normal',
              },
              h2: {
                fontWeight: 'normal',
              },
              h3: {
                fontWeight: 'normal',
              },
              a: {
                textDecorationThickness: '2px',
                textDecorationColor: theme('colors.peach.500'),
                textUnderlineOffset: '5px',
                '&:hover': {
                  textDecorationColor: theme('colors.peach.900'),
                },
                '&:focus': {
                  outlineColor: theme('colors.peach.900'),
                  borderRadius: theme('borderRadius.lg')
                }
              }
            }
          },
        }),
  
        // Sizes
        maxWidth: {
          'screen-sm': '40rem',
          'screen-md': '48rem',
          'screen-lg': '64rem',
          'screen-xl': '80rem',
          'screen-2xl': '96rem',
        },
      },
    },
    plugins: [
        typography,
        forms,
        plugin(function({ addVariant }) {
          addVariant("a11y-typography", ".a11y-typography &");
        })
    ],
}
