import Alpine from 'alpinejs';
import { gsap, Power4 } from "gsap";

export default class MouseParallax {

  /**
   * Init Parallax
   */
  constructor(el, options = {}) {

    // Config
    this.config = {};
    this.config.directions = typeof options.directions !== 'undefined' ? options.directions : ['x', 'y'];
    this.config.scale = typeof options.scale !== 'undefined' ? options.scale : true;
    this.config.multiplier = typeof options.multiplier !== 'undefined' ? options.multiplier : 1;

    // Get the target element
    this.el = el;

    // String selector passed
    if (typeof this.el === 'string' || this.el instanceof String) {
      this.el = document.querySelector(this.el);
    }

    // If empty, exit
    if(this.el == null) {
      return false;
    }

    // Get layers
    this.layers = this.el.querySelectorAll('[data-parallax-layer]');
    if(this.layers.length == 0) {
      return false;
    }

    // Register ticker functions
    this.tickerX = () => { this.moveX(this.layers) };
    this.tickerY = () => { this.moveY(this.layers) };

    // Observer
    this.observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if(entry.isIntersecting) {
          this.start();
        } else {
          this.stop();
        }
      });
    });

    if(!Alpine.store('screensize').isTouchDevice()) {
      this.observer.observe(this.el);
    }
    
    // Init mouse pos at center
    this.mousePos = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    }

    // Update mouse pos
    window.addEventListener("mousemove", e => {
      this.mousePos = {
        x: e.clientX,
        y: e.clientY,
      }
    });

    // Watch a11y event
    document.addEventListener('a11yAnimations', (e) => {
      if(e.detail) {
        this.start();
      } else {
        this.stop();
      }
    })

  }

  /**
   * Move layers on X
   */
  moveX(layers) {
    if(this.config.directions.includes('x')) {
      this.layers.forEach(layer => {
        let move = gsap.quickTo(layer, "xPercent", {duration: 2, ease: Power4.easeOut});
        move(this.getNormalizedPosition('x', layer.getAttribute('data-parallax-speed') * this.config.multiplier));
      });
    }
  }

  /**
   * Move layers on Y
   */
  moveY(layers) {
    if(this.config.directions.includes('y')) {
      this.layers.forEach(layer => {
        let move = gsap.quickTo(layer, "yPercent", {duration: 2, ease: Power4.easeOut});
        move(this.getNormalizedPosition('y', layer.getAttribute('data-parallax-speed') * this.config.multiplier));
      });
    }
  }

  /**
   * Scale layers
   */
  scaleLayers() {
    this.layers.forEach(layer => {
      if(this.config.scale && layer.getAttribute('data-parallax-speed') !== 0) {
        gsap.set(layer, { scale: 1 + ((layer.getAttribute('data-parallax-speed') * 2) / 100) });
      }
      gsap.set(layer, { z: 0.01 }); // Firefox lag hack
    });
  }

  /**
   * Unscale layers
   */
  resetLayers() {
    this.layers.forEach(layer => {
      gsap.set(layer, { scale: 1, yPercent: 0, xPercent: 0 });
    });
  }

  /**
   * Start animations
   */
  start() {
    if(Alpine.store('a11y').options.animations) {
      this.scaleLayers();
      gsap.ticker.add(this.tickerX);
      gsap.ticker.add(this.tickerY);
    }
  }

  /**
   * Stop animations
   */
  stop() {
    this.resetLayers();
    gsap.ticker.remove(this.tickerX);
    gsap.ticker.remove(this.tickerY);
  }
  
  /**
   * Get Normalized Position
   * return round number between -X and X
   */
  getNormalizedPosition(axis, delta = 5) {

    // Check if axis is correct
    if((axis !== 'x') && (axis !== 'y')) {
      console.warn(axis + ' is not an axis.');
      return false;
    }

    // Orientation divider based on axis (vertical or horizontal)
    let orientation = axis == 'x' ? window.innerWidth : window.innerHeight;

    // Normalize the value
    let normalizedAxis = this.mousePos[axis];
    normalizedAxis = (normalizedAxis - orientation / 2) / (orientation / (delta*2));
    normalizedAxis = Math.round(normalizedAxis * 100) / 100;

    return normalizedAxis;
  }

}