/***
 * Modal
 */
export default () => ({
  opened: false,
  openingCounter: 0,
  open() {
    this.opened = true;
    this.openingCounter++;
    window.smoothScroll.stop();
  },
  close() {
    this.opened = false;
    window.smoothScroll.start();
  },
  toggle() {
    if(this.opened) {
      this.close();
    } else {
      this.open();
    }
  }
})