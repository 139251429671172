export default class Region {

  /**
   * Init
   */
  constructor() {
  }

  /**
   * Update
   */
  update(to) {
    let regions = document.querySelectorAll('[data-region]');
    let newRegions = to.page.querySelectorAll('[data-region]');
    regions.forEach(function(region){
      let newRegion = Array.from(newRegions).find((node) => node.dataset.region === region.dataset.region);
      if(newRegion !== undefined ) {
        window.Alpine.morph(region, newRegion);
        window.H.attach(newRegion.querySelectorAll('a:not([data-router-disabled]'));
      }
    });
  }

}