/***
 * Swiper
 */

import Swiper, { Navigation, A11y } from 'swiper';

export default () => ({
  init() {
    new Swiper(this.$el, {
      modules: [Navigation, A11y],
      a11y: true,
      spaceBetween: 100,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 32
        },
        1024: {
          slidesPerView: 2,
          spaceBetween: 64
        },
      }
    });
  },
})