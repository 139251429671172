/***
 * Screen
 * How to use it ? Example :
 * <template x-data x-if="$store.screen.matches['lg']"></template>
 */
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '/tailwind.config.js';

export default {
  screens: resolveConfig(tailwindConfig).theme.screens,
  matches: null,
  orientation: null,
  screenOrientationSupport: false,

  init() {
    this.match();
    this.checkOrientation();
    this.setVh();
    window.addEventListener("resize", (e) => { 
      this.match(); 
    });

    if(screen.orientation !== undefined) {
      this.screenOrientationSupport = true;
    } else {
      this.screenOrientationSupport = false;
    }

    if(this.screenOrientationSupport) {
      screen.orientation.addEventListener('change', (e) => {
        this.checkOrientation();
        setTimeout(() => { this.setVh() }, 100);
      })
    }

    window.addEventListener('resize', (e) => {
      this.checkOrientation();
      setTimeout(() => {

        /* This condition because setVh causes issues on iOS.
        The resize event fires when the topbar of the browser disapear */
        if(!this.isTouchDevice()) {
          this.setVh();
        }
        
      }, 100);
    })

  },

  setVh() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  },

  match() {
    this.matches = Object.entries(this.screens).reduce((results, [name, size]) => {
      let mediaQuery = typeof size === 'string' ? `(min-width: ${size})` : `(max-width: ${size.max})`;
      results[name] = window.matchMedia(mediaQuery).matches;
      return results;
    }, {});
  },

  checkOrientation() {
    if(this.screenOrientationSupport) {
      if(screen.orientation.type == 'portrait-primary' || screen.orientation.type == 'portrait-secondary') {
        this.orientation = 'portrait';
      } else {
        this.orientation = 'landscape';
      }
    } else {
      if(window.matchMedia("(orientation: portrait)").matches) {
        this.orientation = 'portrait';
      } else {
        this.orientation = 'landscape';
      }
    }
  },

  isTouchDevice() {
    return (('ontouchstart' in window) ||
       (navigator.maxTouchPoints > 0) ||
       (navigator.msMaxTouchPoints > 0));
  }

}