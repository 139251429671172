import Highway from '@dogstudio/highway';
import fit from 'fit.js';

export default class HomepageRenderer extends Highway.Renderer {

  onEnter() {
    this.fitMap();
  }

  onLeave() {
    window.removeEventListener('resize', this.fitMap);
  }

  onEnterCompleted() {
    //window.smoothScroll.stop();
    window.addEventListener('resize', this.fitMap);
  }

  onLeaveCompleted() {
    //window.smoothScroll.start();
  }

  fitMap() {

    const container = document.querySelector('.map-wrapper');
    const el = document.querySelector('.map-el');
    Alpine.store('screensize').setVh();
    window.smoothScroll.resize();

    setTimeout(() => {
      fit(
          el,
          container, 
          { cover: true, apply: false, watch: false },
          (transform) => {

            // Set media size
            el.querySelector('.media-hero').style.setProperty('transform-origin', '0px 0px');
            el.querySelector('.media-hero').style.setProperty('transform', `matrix(${transform.scale}, 0, 0, ${transform.scale}, ${transform.tx}, ${transform.ty})`);

            // Resize icons container
            let width = el.querySelector('.media-hero').getBoundingClientRect().width;
            let height = el.querySelector('.media-hero').getBoundingClientRect().height;
            el.querySelector('.map-icons').style.setProperty('width', `${width}px`);
            el.querySelector('.map-icons').style.setProperty('height', `${height}px`);
            el.querySelector('.map-icons').style.setProperty('transform-origin', '0px 0px');
            el.querySelector('.map-icons').style.setProperty('transform', `translateX(${transform.tx}px) translateY(${transform.ty}px)`);

            const icons = el.querySelector('.map-icons').querySelectorAll('.icon');
            icons.forEach(icon => {
              icon.style.setProperty('left', `${icon.dataset.x}%`);
              icon.style.setProperty('top', `${icon.dataset.y}%`);
            });

          }
        );
      }, 200);
  }
  
}
