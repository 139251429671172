/***
 * Hero
 */
import MouseParallax from "../utilities/mouse-parallax";

export default () => ({
  video: null,

  /**
   * Init
   */
  init() {

    this.$nextTick(() => {

      // Init mouse parallax
      new MouseParallax(this.$el, {
        directions: ['x', 'y'],
        scale: true
      })

      // Get video
      this.video = this.$el.querySelector('video');

      // Play video
      if(Alpine.store('a11y').options.animations) {
        this.playVideo();
      }
      
      // Watch a11y event
      document.addEventListener('a11yAnimations', (e) => {
        if(e.detail) {
          this.playVideo();
        } else {
          this.stopVideo();
        }
      })

    });

  },

  /**
   * Play video
   */
  playVideo() {
    if(this.video !== null) {
      this.video.play();
    }
  },

  /**
   * Stop video
   */
  stopVideo() {
    if(this.video !== null) {
      this.video.pause();
    }
  }

})