import Highway from '@dogstudio/highway';
import ScrollReveal from '../../utilities/scroll-reveal';

export default class ContentPageRenderer extends Highway.Renderer {
  onEnter() {
  }
  onLeave() {
  }
  onEnterCompleted() {

    // Text animations
    let scrollReveal = new ScrollReveal();
    
  }
  onLeaveCompleted() {
  }
}
