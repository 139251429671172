/***
 * Username
 */

const defaultName = '';

export default {
  default: defaultName,
  name: localStorage.getItem('user.name') ? localStorage.getItem('user.name') : defaultName,
  set() {
    localStorage.setItem('user.name', this.name);
  },
  clear() {
    this.name = this.default;
    localStorage.removeItem('user.name');
  },
  replaceName(el) {
    const placeholder = el.dataset.placeholder;
    let newText = null;
    if(this.name == '') {
      newText = this.sanitize(placeholder.replace(/(\s*){username}(\s*)/g, ''));
    } else {
      newText = this.sanitize(placeholder.replace('{username}', this.name));
    }
    el.innerHTML = newText;
  },
  sanitize(str) {
    return str.replace(/[^\w. ]/gi, function (c) {
      return '&#' + c.charCodeAt(0) + ';';
    });
  }
}