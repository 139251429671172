/***
 * Music
 */
import {Howl, Howler} from 'howler';

export default {
  bg: null,
  bgMusic: document.querySelector('[data-music-bg]').dataset.musicBg,
  fg: null,
  isMute: false,

  init() {
    this.bg = new Howl({
      src: this.bgMusic,
      volume: 0.2,
      loop: true,
      mute: this.isMute
    });
  },

  playBg() {
    this.bg.play();
  },

  playFg(music) {
    if(music !== '') {
      this.fg = new Howl({
        src: music,
        volume: 1,
        loop: true,
        mute: this.isMute
      });
      this.fg.play();
    }
  },

  playFx(fx) {
    if(!this.isMute) {
      const fxAudio = new Howl({
        src: `/themes/monarchie_kids/src/audio/fx-${fx}.mp3`,
        volume: 0.5,
        mute: this.isMute
      });
      fxAudio.play();
    }
  },

  stopFg() {
    if(this.fg !== null) {
      this.fg.stop();
      this.fg.unload();
      this.fg = null;
    }
  },

  mute() {
    this.isMute = true;
    this.bg.mute(true);
    this.fg.mute(true);
  },

  unMute() {
    this.isMute = false;
    this.bg.mute(false);
    this.fg.mute(false);
  }

}