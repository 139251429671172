/***
 * Gallery
 */

import Swiper, { Navigation, A11y } from 'swiper';

export default (wordings) => ({
  modalOpened: false,
  swiper: null,
  swiperModal: null,
  init() {
    this.total = this.$el.querySelectorAll('[data-media="thumbnail"]').length;

    // Swiper
    this.swiper = new Swiper(this.$el.querySelector('.swiper'), {
      modules: [Navigation, A11y],
      a11y: {
        enabled: true,
        ...wordings
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 'auto'
    });

    this.swiperModal = new Swiper(this.$el.querySelector('.swiper-modal'), {
      modules: [Navigation, A11y],
      a11y: {
        enabled: true,
        ...wordings
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1,
      spaceBetween: 16
    });

  },
  openModal(index) {
    this.swiperModal.slideTo(index-1);
    this.modalOpened = true;
    window.smoothScroll.stop();
  },
  closeModal() {
    this.modalOpened = false;
    window.smoothScroll.start();
  },
})