/***
 * Scroll Call To Action Button
 */
import { gsap, Power4, Bounce, Expo } from "gsap/all";
gsap.registerPlugin(Power4);

export default (config) => ({
  hidden: false,
  tl: null,
  init() {

    gsap.set(this.$el, { opacity: 0 });

    window.smoothScroll.on("scroll", (e) => {
      if(e.targetScroll > 500) {
        if(!this.hidden) {
          this.hide();
          if(this.tl !== null) {
            this.tl.pause();
          }
        }
        this.hidden = true;
      } else {
        if(this.hidden) { 
          this.show();
          if(this.tl !== null) {
            this.tl.play();
          }
        }
        this.hidden = false;
      }
    });

    this.$nextTick(() => {

      // Animate icon
      const iconAnimation = gsap.timeline({
        defaults: {
          ease: Power4.easeInOut,
          duration: 0.8,
        },
        repeat: -1,
        paused: true,
      })
      .fromTo(this.$el.querySelector('.icon'), {
        y: -10,
        opacity: 0
      }, {
        y: 0,
        opacity: 1
      })
      .to(this.$el.querySelector('.icon'), {
        y: 10,
        opacity: 0
      });

      // Animate button
      this.tl = gsap.timeline({ 
        repeat: -1,
        paused: true,
      })
      .fromTo(this.$el, { 
        opacity: 0, 
        y: -50, 
        scale: 0 
      }, { 
        opacity: 1, 
        y: 0, 
        scale: 1,
        duration: 1,
        ease: Bounce.easeOut
      })
      .add(() => { iconAnimation.play(); }, '<')
      .to(this.$el, {
          opacity: 0, 
          y: 20,
          duration: 0.5,
          ease: Power4.easeOut,
      }, '+=2')
      .add(() => { iconAnimation.pause(); iconAnimation.seek(0); }, '<');

      this.tl.play();

    });

  },

  scroll() {
    window.smoothScroll.scrollTo(window.innerHeight);
  },

  hide() {
    gsap.to(this.$el, { opacity: 0 });
  },

  show() {
    gsap.to(this.$el, { opacity: 1 });
  }

})