/***
 * Video
 */
import Plyr from 'plyr';

export default (config) => ({
  init() {
    const player = new Plyr(this.$el, {
      youtube: {
        noCookie: true
      },
      vimeo: {
        dnt: true
      },
      ...config
    });
    player.on('ready', (event) => {
      event.detail.plyr.embed.g.removeAttribute('frameborder');
      event.detail.plyr.embed.g.removeAttribute('width');
      event.detail.plyr.embed.g.removeAttribute('height');
      event.detail.plyr.elements.display.seekTooltip.setAttribute('role', 'tooltip')
    });
  },
})