/***
 * Introduction
 */
import ScrollReveal from "../utilities/scroll-reveal";
import { gsap, Power4, Bounce, Expo } from "gsap/all";
gsap.registerPlugin(Power4);
gsap.registerPlugin(Bounce);
gsap.registerPlugin(Expo);

export default {

  el: document.querySelector('.introduction'),
  bg: document.querySelector('.introduction-bg'),
  backlink: document.querySelector('.introduction-backlink'),
  loading: document.querySelector('.introduction-loading'),
  modal: document.querySelector('.introduction-modal'),
  currentStep: 1,
  steps: document.querySelectorAll('.introduction-modal-step'),

  /**
   * Setup
   */
  setup() {
    if(!this.el) return false;

    // Stop scrolling
    window.smoothScroll.stop();

    // Hide background
    gsap.to(this.bg, {
      opacity: 0,
      duration: 0.5
    });

    // Hide by default
    gsap.set(this.modal, { opacity: 0 });
    this.modal.classList.add('pointer-events-none');

    // Hide backlink
    gsap.set(this.backlink, {
      y: -200
    });

    // Hide backlink
    gsap.set(document.querySelectorAll('.tools-item'), {
      y: 200
    });

    window.addEventListener('resize', () => {
      gsap.set(this.modal, {
        height: 'auto',
        onComplete: () => {
          gsap.set(this.modal, { height:  this.modal.getBoundingClientRect().height });
        }
      });
    });

    // Start loading
    this.loadingAnimation();

  },

  /**
   * Loading Animation
   */
  loadingAnimation() {

    // Elements
    const wrapper = this.loading.querySelector('.introduction-loading-wrapper');
    const barBg = this.loading.querySelector('.introduction-loading-bar-bg');
    const barProgress = this.loading.querySelector('.introduction-loading-bar-progress');

    // Set some things
    gsap.set(wrapper, { 'will-change': 'transform, opacity' });
    gsap.set(barProgress, { 'will-change': 'width' });

    // Animation
    gsap.timeline({
      defaults: {
        duration: 0.5,
        ease: Expo.easeInOut
      },
    })
    .fromTo(wrapper, { scale: 0 }, { scale: 1, duration: 0.8, ease: Bounce.easeOut })
    .fromTo(barProgress, { width: '0%' }, { width: '20%' }, '<')
    .to(barProgress, { width: '60%' })
    .to(barProgress, { width: '100%' })
    .to(wrapper, { scale: 0, opacity: 0 })
    .to(this.backlink, { y: 0, duration: 1, }, '<')
    .to(document.querySelectorAll('.tools-item'), { 
      y: 0, 
      stagger: 0.05, 
      duration: 1,
      onComplete: function () {
        gsap.set(this.targets(), { clearProps: "all" });
      }
    }, '<')
    .add(() => {
      gsap.set(this.modal, {
        height: this.modal.getBoundingClientRect().height,
        y: window.innerHeight + this.modal.getBoundingClientRect().height/2,
        rotate: -45,
        opacity: 1
      });
      this.modal.classList.remove('pointer-events-none');
    }, '<')
    .to(this.modal, {
      y: 0,
      rotate: 0,
      duration: 0.8,
      ease: Expo.easeInOut
    }, '<')
    .add(() => { 
      this.loading.remove();
      new ScrollReveal({ container: this.steps[this.currentStep].el });
    }, '-=0.5');

  },

  /**
   * Next step
   */
  next() {
    this.currentStep++;
    this.$focus.focus(this.steps[this.currentStep-1]);
    this.$nextTick(() => {
      gsap.to(this.modal, {
        height: 'auto',
        duration: 0.5,
        ease: Bounce.easeOut
      });
    });
    
  },

  /**
   * Close
   */
  close(skip = false) {
    window.smoothScroll.start();
    this.$focus.focus(document.querySelector('#top'));

    if (skip) {
      Alpine.store('user').clear();
    }

    gsap.timeline({ 
      defaults: {
        duration: 0.8,
        ease: Expo.easeInOut
      }
    })
    .to(this.backlink, { y: -200 }, '<')
    .to(this.modal, {
      y: window.innerHeight,
      rotate: -45,
    }, '<')
    .to(this.el, {
      opacity: 0
    }, '-=50%')
    .add(() => { 
      this.el.parentNode.remove();
      Alpine.store('app').enter();
    })
  }

}