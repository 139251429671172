/***
 * Alpine scripts
 * -----------------------------------------------
 */
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import morph from '@alpinejs/morph';
import introduction from './alpine/introduction.js';
import transition from './alpine/transition.js';
import progress from './alpine/progress.js';
import screensize from './alpine/screensize.js';
import quizz from './alpine/quizz.js';
import user from './alpine/user.js';
import menu from './alpine/menu.js';
import modal from './alpine/modal.js';
import music from './alpine/music.js';
import a11y from './alpine/a11y.js';
import hero from './alpine/hero.js';
import illustration from './alpine/illustration.js';
import decoration from './alpine/decoration.js';
import video from './alpine/video.js';
import audio from './alpine/audio.js';
import swiper from './alpine/swiper.js';
import gallery from './alpine/gallery.js';
import scrollCtaButton from './alpine/scroll-cta-button.js';
import app from './alpine/app.js';

/***
 * Highway scripts
 * -----------------------------------------------
 */
import Highway from '@dogstudio/highway';
import NodeRenderer from "./highway/renderer/node-renderer";
import PageRenderer from "./highway/renderer/page-renderer";
import ContentPageRenderer from "./highway/renderer/content-page-renderer";
import HomepageRenderer from "./highway/renderer/homepage-renderer.js";
import Slide from "./highway/transition/slide.js";

/***
 * Misc scripts
 * -----------------------------------------------
 */
import AdminTabs from "./adminTabs";
import ActiveLink from "@explose/activelink";
import Region from "./utilities/region.js";
import ScrollSmooth from "./utilities/scroll-smooth.js";
import Bowser from "bowser";


/***
 * Browser informations
 * -----------------------------------------------
 */
window.browser = Bowser.parse(window.navigator.userAgent);
document.documentElement.classList.add('browser-'+window.browser.browser.name.replace(/\s+/g, '-').toLowerCase());
document.documentElement.classList.add('os-'+window.browser.os.name.replace(/\s+/g, '-').toLowerCase());
document.documentElement.classList.add('platform-'+window.browser.platform.type.replace(/\s+/g, '-').toLowerCase());


/***
 * Set VH
 * Explanations: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 * -----------------------------------------------
 */
// window.setVh = () => {
//   let vh = screen.height * 0.01;
//   console.log(window.visualViewport.height);
//   document.documentElement.style.setProperty('--vh', `${vh}px`);
// }
// window.setVh();

// On resize
// window.addEventListener('resize', () => {
//   setVh();
// });

// On orientation change
// let orientation = screen.orientation;
// if(orientation !== undefined) {
//   document.documentElement.classList.add('orientation-' + orientation.type);
//   window.addEventListener('orientationchange', (e) => {
//     setVh(e);
//     document.documentElement.classList.remove('orientation-' + orientation.type);
//     orientation = screen.orientation.type;
//     document.documentElement.classList.add('orientation-' + orientation.type);
//   });
// }

const detachAdminLinks = () => {
  if (document.getElementById("admin-tools")) {
    window.H.detach(document.getElementById("admin-tools").querySelectorAll("a"));
  }
}

/**
 * Redirect
 */

const metas = document.getElementsByTagName('meta');

for (let i = 0; i < metas.length; i++) {
  if (metas[i].getAttribute('name') === 'robots') {
    let index = metas[i].getAttribute('content');
    if (index == 'noindex'){
      const {
        host, hostname, href, origin, pathname, port, protocol, search
      } = window.location;

      let path_parts = pathname.split("/").filter(n => n);
      if (path_parts.length > 1){
        if (!document.getElementById("admin-tools")) {
          window.location = origin + '/' + path_parts[0];
        }
      }
    }
  }
}


/***
 * Init app
 * -----------------------------------------------
 */
window.addEventListener('load', () =>{

  // Alpine start
  document.addEventListener('alpine:init', () => {

    // Stores
    Alpine.store('transition', transition)
    Alpine.store('introduction', introduction)
    Alpine.store('progress', progress)
    Alpine.store('screensize', screensize)
    Alpine.store('user', user)
    Alpine.store('menu', menu)
    Alpine.store('a11y', a11y)
    Alpine.store('app', app)
    Alpine.store('music', music)

    // Datas
    Alpine.data('quizz', quizz)
    Alpine.data('modal', modal)
    Alpine.data('hero', hero)
    Alpine.data('illustration', illustration)
    Alpine.data('decoration', decoration)
    Alpine.data('video', video)
    Alpine.data('audio', audio)
    Alpine.data('swiper', swiper)
    Alpine.data('gallery', gallery)
    Alpine.data('scrollCtaButton', scrollCtaButton)

    // Smooth scroll
    new ScrollSmooth();

  });
  Alpine.plugin(focus);
  Alpine.plugin(morph);
  Alpine.start();
  window.Alpine = Alpine;

  // Constructors
  const adminTabs = new AdminTabs();
  const activeLink = new ActiveLink();
  const region = new Region();

  // Highway
  window.H = new Highway.Core({
    renderers: {
      homepage: HomepageRenderer,
      node: NodeRenderer,
      page: PageRenderer,
      contentpage: ContentPageRenderer
    },
    transitions: {
      default: Slide
    }
  });
  detachAdminLinks();

  // Navigate IN
  window.H.on('NAVIGATE_IN', ({ to }) => {

    // If scroll is stopped, restart.
    if(window.smoothScroll.isStopped) {
      window.smoothScroll.start();
    }

    document.getElementById('top').focus();
    const previousBodyClass = document.body.classList;
    const newBodyClass = to.page.body.className.split(' ');
    document.body.classList = newBodyClass.join(' ');
  });

  // Navigate OUT
  window.H.on('NAVIGATE_OUT', ({ from, trigger, location }) => {
    //Alpine.store('app').referrer = from.page.URL;
  });

  // Navigate END
  window.H.on('NAVIGATE_END', ({ to }) => {
    window.H.cache.clear();
    activeLink.update();
    Alpine.store('menu').updateLinkToCurrentPage();
    region.update(to);
    detachAdminLinks();

    _paq.push(['setDocumentTitle', to.page.title]);
    _paq.push(['setCustomUrl', location.href]);
    _paq.push(['disableCookies']);
    _paq.push(['trackPageView']);

  });

  if (document.getElementById("admin-tools")) {
    setTimeout(() => {
      Alpine.store('app').isAdmin = true;
      Alpine.store('app').enter();
    }, 100);

  }

});
