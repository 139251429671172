/***
 * Decoration
 */
import MouseParallax from "../utilities/mouse-parallax";
import ScrollParallax from "../utilities/scroll-parallax";

export default (parallaxType, parallaxMultiplier) => ({

  parallaxType: parallaxType,
  parallaxMultiplier: parallaxMultiplier,

  /**
   * Init
   */
  init() {

    // Init mouse parallax
    this.$nextTick(() => {

      if(this.parallaxType == 'mouse') {
        new MouseParallax(this.$el, { multiplier: this.parallaxMultiplier });
      }

      if(this.parallaxType == 'scroll') {
        new ScrollParallax(this.$el);
      }
      
    });    

  },

})