/***
 * Quizz
 */
export default () => ({
  activeQuestion: 0,
  goodAnswer: null,
  feedback: null,
  end: false,
  score: 0,
  questions: document.querySelectorAll('[data-question]'),
  startScreen: document.querySelector('#quizz-start'),
  endScreen: document.querySelector('#quizz-end'),
  start() {
    this.activeQuestion = 1;
    this.goodAnswer = this.questions[this.activeQuestion-1].querySelector('[data-answer][data-true] [data-answer-value]').textContent;
  },
  submit() {
    if(this.$el.parentNode.hasAttribute('data-true')) {
      this.feedback = 'positive';
      this.score++;
      this.$focus.focus(this.questions[this.activeQuestion-1].querySelector('[data-feedback-positive]'));
    } else {
      this.feedback = 'negative';
      this.$focus.focus(this.questions[this.activeQuestion-1].querySelector('[data-feedback-negative]'));
    }
  },
  next() {
    window.smoothScroll.scrollTo(0);
    this.goodAnswer = null;
    this.feedback = null;
    this.activeQuestion++;
    if(this.activeQuestion > this.questions.length) {
      this.finish();
    } else {
      this.goodAnswer = this.questions[this.activeQuestion-1].querySelector('[data-answer][data-true] [data-answer-value]').textContent;
      this.$focus.focus(this.questions[this.activeQuestion-1]);
    }
  },
  finish() {
    this.activeQuestion = null;
    this.end = true;
    this.$focus.focus(this.endScreen);
  },
  reset() {
    this.activeQuestion = 0;
    this.goodAnswer = null;
    this.feedback = null;
    this.end = false;
    this.score = 0;
    this.$focus.focus(this.startScreen);
  }
})