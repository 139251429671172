/***
 * Progress
 * How to use it ? Example :
 * x-on:click="$store.progress.complete(1, 2, Sample paragraph 2)"
 * x-text="$store.progress.getPage(2).completed + '/' + $store.progress.getPage(2).total"
 */
import lottie from 'lottie-web/build/player/lottie_light.min.js';
import popEffect from '../../img/pop.json';
import { gsap, Power4 } from "gsap/all";
gsap.registerPlugin(Power4);

export default {
  tree: null,
  defaultTree: null,
  token: null,
  pageProgress: {
    completed: 0,
    total: 0,
    percent: 0
  },
  totalProgress: {
    completed: 0,
    total: 0,
    percent: 0
  },
  getMousePos: (e) => {
    return {
      x: e.clientX,
      y: e.clientY
    }
  },

  // Init
  load(data) {

    this.defaultTree = JSON.parse(atob(data));

    if(localStorage.getItem('progress-tree') == null) {
      this.tree = this.defaultTree;
      localStorage.setItem('progress-tree', JSON.stringify(this.tree));
    } else {
      this.tree = JSON.parse(localStorage.getItem('progress-tree'));
    }

  },

  completeAll() {

    // Complete the task
    this.tree = this.tree.map(item => ({
      ...item,
      pages: item.pages.map(page => ({
          ...page,
          tasks: page.tasks.map(task => ({
              ...task,
              completed: true
          }))
      }))
    }));
    localStorage.setItem('progress-tree', JSON.stringify(this.tree));

    document.dispatchEvent(new CustomEvent('progresscompletedallpopup'));
    this.popAnimation({ container: document.querySelector('.pop-completedall-popup'), loop: true });

  },

  // Complete a task
  complete(taskId, event) {

    // Get the task
    let task = Object.values(this.tree)
                      .flatMap(Object.values)
                      .flatMap(pages => pages)
                      .flatMap(Object.values)
                      .flatMap(tasks => tasks)
                      .find(task => task.id === taskId);

    // Check current page progress
    let currentPage = null;
    const completedTask = task;
    for (const item of this.tree) {
      for (const page of item.pages) {
        for (const task of page.tasks) {
          if (task.id === completedTask.id) {
            currentPage = page;
            break;
          }
        }
        if (currentPage) {
          break;
        }
      }
    }

    if(!task.completed) {
      
      task.completed = true;
      localStorage.setItem('progress-tree', JSON.stringify(this.tree));
      Alpine.store('music').playFx('transition');
      this.popAnimation({ container: document.querySelector('.pop-task'), loop: false });
      
      // If current page progress is 100, show a popup
      if(this.getPage(currentPage.id).percent == 100 && this.getTotal().percent < 100) {
        document.dispatchEvent(new CustomEvent('progresscompletedpopup'));
        this.popAnimation({ container: document.querySelector('.pop-completed-popup'), loop: true });
      } 
      
      // If current page progress is 100, show a popup AND total of progress is 100
      else if(this.getPage(currentPage.id).percent == 100 && this.getTotal().percent == 100) {
        document.dispatchEvent(new CustomEvent('progresscompletedallpopup'));
        this.popAnimation({ container: document.querySelector('.pop-completedall-popup'), loop: true });
      }

    }
  
    // Reset token
    this.token = null;
    
  },

  // Pop Animation
  popAnimation(options) {
    options.container.innerHTML = '';
    lottie.loadAnimation({
      container: options.container,
      renderer: 'svg',
      autoplay: false,
      loop: options.loop,
      animationData: popEffect
    });
    lottie.setSpeed(0.5);
    lottie.play();
  },

  // Get total progress
  getTotal() {

    let allTasks = this.tree.reduce((acc, item) => {
      const tasks = item.pages.map(page => page.tasks).flat();
      return [...acc, ...tasks];
    }, []);
    let completedTasks = allTasks.filter(task => task.completed === true);
    let percent = (completedTasks.length > 0 && allTasks.length > 0) ? (completedTasks.length/allTasks.length) * 100 : 0;

    this.totalProgress = {
      completed: completedTasks.length,
      total: allTasks.length,
      percent: Math.round(percent)
    }
    return this.totalProgress;

  },

  // Get a progress based on a page id
  getPage(pageId) {

    let page = Object.values(this.tree)
                      .flatMap(Object.values)
                      .flatMap(pages => pages)
                      .find(page => page.id === pageId.toString());

    if(page == undefined) {
      return false;
    }
    
    let completedTasks = page.tasks.length > 0 ? page.tasks.filter(task => task.completed === true) : [];
    let percent = (completedTasks.length > 0 && page.tasks.length > 0) ? (completedTasks.length/page.tasks.length) * 100 : 0;

    this.pageProgress = {
      completed: completedTasks.length,
      total: page.tasks.length,
      percent: Math.round(percent)
    }
    return this.pageProgress;

  },

  // Restart a page from the top
  restart(pageId) {
    let page = Object.values(this.tree)
                      .flatMap(Object.values)
                      .flatMap(pages => pages)
                      .find(page => page.id === pageId.toString());

    page.tasks.forEach(task => {
      task.completed = false;
    });
    localStorage.setItem('progress-tree', JSON.stringify(this.tree));
    window.smoothScroll.scrollTo('top');
    document.getElementById('top').focus();
  },

  // Clear all progress and refresh
  clear() {
    localStorage.removeItem('progress-tree');
    this.tree = this.defaultTree;
    this.pageProgress = {
      completed: 0,
      total: 0,
      percent: 0
    }
    location.reload();
  }

}