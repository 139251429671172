import Highway from '@dogstudio/highway';
import { gsap, Bounce, Power4, Circ } from "gsap";
gsap.registerPlugin(Bounce);
gsap.registerPlugin(Power4);
gsap.registerPlugin(Circ);
import MediasLoaded from "../../utilities/medias-loaded.js";

export default class Slide extends Highway.Transition {

  out({ from, trigger, done }) {

    // Close the menu
    if(Alpine.store('menu').isOpen) {
      Alpine.store('menu').close();
    }

    // Even listener on transition half
    document.addEventListener('transitionHalf', () => {
      from.remove();
      window.smoothScroll.scrollTo(0, { immediate: true });
      done();
    }, { once: true });

    // Transition out
    Alpine.store('transition').out();

  }

  in({ from, to, trigger, done }) {

    // Resize scroll
    window.smoothScroll.resize();

    // On transition completed
    document.addEventListener('transitionCompleted', () => {
      if(trigger !== 'popstate' && trigger.hasAttribute('data-progress-token')) {
        Alpine.store('progress').complete(trigger.getAttribute('data-progress-token'));
      }
    }, { once: true });

    // Execute transition only if hero medias are loaded
    // Need to have a set timeout to wait alpine has finished his stuff
    setTimeout(() => {

      // Leave transition when images are loaded
      const hero = to.querySelector('[x-data="hero"]');
      if(hero) {
        const medias = to.querySelector('[x-data="hero"]').querySelectorAll('img, video');
        new MediasLoaded(medias).then(() => {
          Alpine.store('transition').in();
          done();
        });
      } else {
        Alpine.store('transition').in();
        done();
      }

      // Scroll to last position
      if(trigger !== 'popstate' && trigger.hasAttribute('data-scrollto')) {
        window.smoothScroll.scrollTo(parseInt(trigger.getAttribute('data-scrollto')), { immediate: true });
      }

    }, 100);

  }

}